import React from "react";
import d1 from "../assets/d1.png";
import d2 from "../assets/d2.png";
import d3 from "../assets/d3.png";
import ft from "../assets/ft.png";
import omni from "../assets/omni.png";
import s1 from "../assets/s1.png";
import s2 from "../assets/s2.png";
import s3 from "../assets/s3.png";

const SpendM = () => {
  return (
    <>
      <div className="spend container">
        <div>
          <div className="head-sec">
            <h4>
              It’s never too early or too late to streamline operations and
              boost efficiency.
            </h4>
            <p>
              Whether you’re a global enterprise looking to save time and money
              or a high-velocity business with ambitious goals, our cutting-edge
              global payment technology can help you get there.
            </p>
          </div>
          <section>
            <div>
              <div className="cards">
                <aside>
                  <img alt="" src={s1} />
                  <label>Scale ups</label>
                  <p>
                    Our quick setup, intuitive dashboard, and smart controls
                    help businesses with small finance teams bring order to
                    their spend management while accelerating growth.
                  </p>
                </aside>
                <aside>
                  <img alt="" src={s2} />
                  <label>Mid-size companies</label>
                  <p>
                    Use a single platform to simplify card issuance, automate
                    processes, and implement controls that empower your teams
                    while optimising resources.
                  </p>
                </aside>
                <aside>
                  <img alt="" src={s3} />
                  <label>Enterprise</label>
                  <p>
                    Set up complex, multi-entity workflows, controls, and
                    automations at a global scale and take advantage of blueback
                    and ERP integrations to streamline all your business tools.
                  </p>
                </aside>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section className="dedicated products pb-5">
        <div className="container">
          <div className="head-sec">
            <h4>Dedicated to your success from day one</h4>
          </div>
          <div className="cards">
            <aside>
              <div>
                <img alt="" src={d1} />
              </div>
              <label>Smooth onboarding and implementation</label>
              <p>
                Our quick setup, intuitive dashboard, and smart controls help
                businesses with small teams go-live in no time. Bluerack can
                assist you for any complex or custom configurations.
              </p>
            </aside>
            <aside>
              <div>
                <img alt="" src={d2} />
              </div>
              <label>Interact with our AI-Powered Assist.</label>
              <p>
                Our AI-Powered assistant helps you with interactive sessions on
                how to use the platform, some pre-built setup configurations.
              </p>
            </aside>
            <aside>
              <div>
                <img alt="" src={d3} />
              </div>
              <label>Dedicated Customer success manager</label>
              <p>
                Our customer support team helps you on any kind of challenges on
                the platform. With a dedicated relationship manager assigned to
                each account.
              </p>
            </aside>
          </div>
        </div>
      </section>
      <section className="products pb-5">
        <div className="container">
          <div className="head-sec">
            <h4>Forward-thinking businesses run on Bluerack</h4>
          </div>
          <div className="ft">
            <img alt="" className="main" src={ft} />
            <div className="case-study">
              <img alt="" src={omni} />
              <p>
                OMNI United, Singapore, a leading tire manufacturer, has
                digitised their entire distribution channel. They have
                implemented modules for order management, payment collections,
                and channel incentivisation. The platform is seamlessly
                integrated with their SAP system for data reconciliation.
              </p>
              <div className="line" />
              <p>Read case study →</p>
            </div>
            {/* <img
              alt=""
              className="main mobile"
              src={ft_m}
              style={{ width: "100%" }}
            /> */}
          </div>
        </div>
      </section>
      {/* <section className="products">
        <div className="head-sec">
          <h4>General FAQ's</h4>
        </div>
        <Accordion
          items={[
            {
              id: "faq-1",
              title: "What is GST?",
              text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur. Reprehenderit magnam necessitatibus vel culpa provident quas nesciunt sunt aut cupiditate fugiat!",
            },
            {
              id: "faq-2",
              title: "How to enrol for GST?",
              text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur. Reprehenderit magnam necessitatibus vel culpa provident quas nesciunt sunt aut cupiditate fugiat!",
            },
          ]}
        />
      </section> */}
    </>
  );
};

export default SpendM;
