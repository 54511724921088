import React, { useRef } from "react";
import arrow from "../assets/arrow.png";
import landing_img from "../assets/landing_img.png";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import axios from "axios";
const LandingPage = ({ fromContact }) => {
  const navigate = useNavigate();
  const formRef = useRef();

  const submittedDetails = async (data) => {
    axios
      .post(
        // "https://dummyjson.com/products/add",
        "https://notificationapi.forgocards.com/v1/notifications/0/sendEmailToLead",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNWNiY2Q5MTMtMWU3Ni00ODVjLWJiY2ItMGVjODQ0YzY4NTFkIiwiY29tcGFueV9pZCI6IjM1ZmFhZDFiLTlhMjgtNDgxMy1hODQyLTdjZTdmYTEzNmU5MSIsInNlc3Npb25faWQiOiI4ZGEwM2NlMS01NDBkLTQyNWMtYTc1OC02NzhjMWU5NzY1NTkiLCJpYXQiOjE2OTQ3MDA0MTksImV4cCI6MTY5NDcwNDAxOX0.p9S1aZeSYcr2OFk3SlNO3hI40fvQDG4303Bq8fS-KORaN3GkyQ48wIzwFAjc93NyJbnAIfWF_gl6vc7cIqBqiMt4hMvDghzxFQREwPItGvMnrWBhg87CuEdVoEmzs9Q_3DIh85xi47A785N8_olZyxDj-ulsaj-RfFCT_32Wr6BykO1qY6i3dkmxhCKr-4Cb3GtUYZ0QX1ft9asZuMYLoFnvx1GHzXqS2wb8eNdWkgNUXWuMyet5JReOylFzhb1xX14tIrf6IBFvD6xzQWZ5uslFV93dXhHMafZYVlSYBUu--QH6L1S4JonqT0CDjSpeaOCKWYlQnhPxaLD7c56sfg`,
          },
        }
      )
      .then((response) => {
        formRef.current.reset();
        toast.success("Thank You for Contact Us...", {
          autoClose: 5000,
        });
        console.log(response.data.message);
      })
      .catch((response) => {
        toast.error("please enter correct details", {
          autoClose: 5000,
        });
        console.log(response);
      });
  };
  return (
    <div className="landing-page">
      {/* Main Section */}
      {fromContact ? (
        <section className="hero-section">
          <div className="container">
            <div className="flex-between">
              <div className="hero-left">
                <h6 className="hero-title">
                  Let’s talk to our product experts
                </h6>
                <p className="hero-subtitle">
                  Building the future of finance is tough. But you don’t have to
                  do it alone. Reach out and one of our experts will be in touch
                  shortly to let you know how Bluerack can help.
                </p>
              </div>
              <div className="hero-form">
                <form
                  ref={formRef}
                  onSubmit={(e) => {
                    e.preventDefault();
                    const userDetails = {
                      name: formRef.current?.username?.value,
                      email_id: formRef.current?.email_id?.value,
                      mobile_number: formRef.current?.mobile_number?.value,
                      description: formRef.current?.description?.value,
                    };
                    submittedDetails(userDetails);
                  }}
                >
                  <div>
                    <input placeholder="*Name" name="username" />
                  </div>
                  <div>
                    <input placeholder="*Email" name="email_id" />
                  </div>
                  <div>
                    <input placeholder="*Phone" name="mobile_number" />
                  </div>
                  <div>
                    <input
                      placeholder="*How can Bluerack Assist You ?"
                      name="description"
                    />
                  </div>

                  <label htmlFor="by-submit">
                    <p>
                      <input type="checkbox" name="opt_in" />
                      <span className="checkmark" />
                    </p>
                    <p>
                      By submitting this form you agree that your information
                      will be used in accordance with Privacy Policy
                    </p>
                  </label>

                  <button
                    style={{
                      marginTop: "2rem",
                      marginLeft: "2rem",
                    }}
                    type="submit"
                    className="btn-light"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="hero-section">
          <div className="container">
            <div>
              <div className="hero-left">
                <h6 className="hero-title">
                  One platform, all the tools you need to grow
                </h6>
                <p className="hero-subtitle">
                  We empower enterprises across the value chain, enhancing
                  customer experience, cutting costs, and boosting operational
                  efficiency with our AI Powered Solutions.
                </p>
                <div className="g-started">
                  <button
                    className="btn-light"
                    onClick={() => {
                      navigate("/contact-us");
                    }}
                  >
                    Get started for free <img alt="" src={arrow} width={11} />
                  </button>
                </div>
              </div>
              <div className="hero-image">
                <img
                  src={landing_img}
                  alt="Dashboard"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>
      )}
      <ToastContainer position="top-right" />
    </div>
  );
};

export default LandingPage;
