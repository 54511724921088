import React from "react";
import u1 from "../assets/u1.png";
// import u2 from "../assets/u2.png";
import y1 from "../assets/y1.png";
import y2 from "../assets/y2.png";
const Unprecedented = () => {
  return (
    <>
      <div className="unprecedented container">
        <div className="head-sec">
          <h4>Driving value to every department</h4>
          <p>
            Bluerack is a single platform that provides anyone the tools needed
            to automate processes, improve efficiency, and streamline workflows.
            Unlock unprecedented visibility and control, while saving time and
            money.
          </p>
        </div>
        <img alt="" className="main" src={u1} style={{ height: "90vh" }} />
        <section className="your-money">
          <div>
            <div className="head-sec">
              <span>BANKS SECURITY X FINTECH EFFICIENCY</span>
              <h4>Your Money Is in Safe Hands</h4>
            </div>
            <div className="cards">
              <div>
                <img alt="" src={y1} />
                <section>
                  <label>Cutting-edge Al technology</label>
                  <p>
                    We protect you and your transactions from fraudsters 24/7
                    thanks to advanced Al supported verification processes.
                  </p>
                </section>
              </div>

              <div>
                <img alt="" src={y2} />
                <section>
                  <label>Fraud and data theft protection</label>
                  <p>
                    Never worry about your card data with blueback. We’re
                    PCI-DSS level 1 certified, and it’s our mission to provide
                    protected payment solutions.
                  </p>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Unprecedented;
