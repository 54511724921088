import React from "react";
import t1 from "../assets/t1.png";
import t2 from "../assets/t2.png";
// import t3 from "../assets/t3.png";
import t4 from "../assets/t4.png";
import t5 from "../assets/t5.png";
// import t6 from "../assets/t6.png";
import t7 from "../assets/t7.png";
import t8 from "../assets/t8.png";
import t9 from "../assets/t9.png";
import t10 from "../assets/t10.png";
import Marquee from "react-fast-marquee";

const TrustedNWhy = () => {
  return (
    <div>
      <section className="container trusted">
        <p>TRUSTED BY COMPANIES ACROSS THE WORLD</p>
        <Marquee>
          <ul>
            <li>
              <img
                alt=""
                src={t1}
                style={{
                  marginLeft: "5rem",
                }}
              />
            </li>
            <li>
              <img alt="" src={t2} />
            </li>
            {/* <li>
              <img alt="" src={t3} />
            </li> */}
            <li>
              <img alt="" src={t4} />
            </li>
            <li>
              <img alt="" src={t5} />
            </li>
            {/* <li>
              <img alt="" src={t6} />
            </li> */}
            <li>
              <img alt="" src={t7} />
            </li>
            <li>
              <img alt="" src={t8} />
            </li>
            <li>
              <img alt="" src={t9} />
            </li>
            <li>
              <img alt="" src={t10} />
            </li>
          </ul>
        </Marquee>
      </section>

      <section className="why-br container">
        <div>
          <span>Why Bluerack ?</span>
          <p className="ans">
            Global Payments and Finance made simple. We streamline the entire
            value chain from procurement to sales, offering tailored solutions
            driven by our proprietary technology. Our services enable businesses
            of all scales to automate processes, improve supply chain
            efficiency, and unlock insights with AI-enhanced data analytics.
          </p>
          <ul>
            <li>
              <label>35+</label>
              <p>Countries where you can open local currency accounts.</p>
            </li>
            <li>
              <label>180+</label>
              <p>Countries from which you can accept payments</p>
            </li>
            <li>
              <label>80+</label>
              <p>Countries to which you can make transfers</p>
            </li>
            <li>
              <label>70%</label>
              <p>Transfers arrive within the same day</p>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default TrustedNWhy;
