import React from "react";
import p1 from "../assets/p1.png";
import p2 from "../assets/p2.png";
import p3 from "../assets/p3.png";
import p4 from "../assets/p4.png";
import p5 from "../assets/p5.png";
import p6 from "../assets/p6.png";
import p7 from "../assets/p7.png";
import b1 from "../assets/b1.png";
import b2 from "../assets/b2.png";
import b3 from "../assets/b3.png";
const Products = () => {
  const cards = [
    {
      title: "Global Accounts",
      value: `Open an international business account online to receive international payments
from customers in their preferred currency. You can then spend the proceeds in 
the same currency or send it to your settlement currency.`,
      img: p1,
    },
    {
      title: "Borderless Cards",
      value: `Issue multi-currency expense cards to your team members around the world to streamline their local and global purchases.`,
      img: p2,
    },
    {
      title: "FX & Transfers",
      value: `Pay your global suppliers and employees without the expense and hassle of the traditional banking system. Pay them through Virtual cards instantly.`,
      img: p3,
    },
    {
      title: "Supply Chain Optimization",
      value: `We empowers enterprises across the value chain, enhancing customer experience, cutting costs, and boosting operational efficiency.`,
      img: p4,
    },
    {
      title: "Travel & Expense",
      value:
        "Streamline the reconciliation of corporate card expenses and reimbursements in one platform.",
      img: p5,
    },
    {
      title: "Procure to Pay",
      value:
        "From simple bill pay to complex purchase orders, Sync all vendor and payment details to your ERP.",
      img: p6,
    },
    {
      title: "Integrations",
      value:
        "Our API’s allow your team to effortlessly develop a integration with your ERP system.",
      img: p7,
    },
  ];

  return (
    <div>
      <section className="container products pb-5">
        <div className="head-sec">
          <span>Products</span>
          <h4>
            Everything you need to efficiently manage and run your business
            globally
          </h4>
        </div>
        <div className="cards">
          <div className="bottom">
            <label>{cards?.[0]?.title}</label>
            <p>{cards?.[0]?.value}</p>
            <img alt="" src={cards?.[0]?.img} />
          </div>
          <div>
            <label>{cards?.[1]?.title}</label>
            <p>{cards?.[1]?.value}</p>
            <img alt="" src={cards?.[1]?.img} />
          </div>
        </div>
        <div className="cards">
          <div>
            <label>{cards?.[2]?.title}</label>
            <p>{cards?.[2]?.value}</p>
            <img alt="" src={cards?.[2]?.img} />
          </div>
          <div>
            <label>{cards?.[3]?.title}</label>
            <p>{cards?.[3]?.value}</p>
            <img alt="" src={cards?.[3]?.img} />
          </div>
        </div>
        <div className="cards">
          <div>
            <label>{cards?.[4]?.title}</label>
            <p>{cards?.[4]?.value}</p>
            <img alt="" src={cards?.[4]?.img} />
          </div>
          <div>
            <label>{cards?.[5]?.title}</label>
            <p>{cards?.[5]?.value}</p>
            <img alt="" src={cards?.[5]?.img} />
          </div>
          <div>
            <label>{cards?.[6]?.title}</label>
            <p>{cards?.[6]?.value}</p>
            <img alt="" src={cards?.[6]?.img} />
          </div>
        </div>
      </section>
      <section className="build-to-scale products pb-5">
        <div className="container">
          <div className="head-sec">
            <h4>Built to scale with you</h4>
            <p>
              Whether you’re building your business, expanding internationally,
              or cutting costs - Bluerack works for you.
            </p>
          </div>
          <div className="cards">
            <aside>
              <div>
                <img alt="" src={b1} />
              </div>
              <label>Operate globally</label>
              <p>
                Send payments to 180 countries in over 35 currencies and
                reimburse employees in their local currencies within 2 days.
              </p>
            </aside>
            <aside>
              <div>
                <img alt="" src={b2} />
              </div>
              <label>Completely flexible</label>
              <p>
                Customise bluerack to fit your business and give you the
                controls you need with policies, roles, and approval workflows.
              </p>
            </aside>
            <aside>
              <div>
                <img alt="" src={b3} />
              </div>
              <label>Integrate and stay synced</label>
              <p>
                Seamlessly integrate bluerack with your accounting systems and
                consolidate your finance stack.
              </p>
            </aside>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Products;
