import LandingPage from "./components/LandingPage";
import TrustedNWhy from "./components/TrustedNWhy";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Footer from "./components/Footer";
import Products from "./components/Products";
import SpendM from "./components/SpendM";
import Unprecedented from "./components/Unprecedented";
import logo from "./assets/logo.png";
import arrow from "./assets/arrow.png";
import { useEffect } from "react";

const Header = () => {
  const navigate = useNavigate();
  return (
    <header>
      <div className="flex-between">
        <div className="nav-bar">
          <img
            alt=""
            src={logo}
            className="logo cursor"
            onClick={() => {
              navigate("/");
            }}
          />
          {/* <ul>
        <li className="nav-item">For Enterprises</li>
        <li className="nav-item">For Banks</li>
        <li className="nav-item">Resources</li>
        <li className="nav-item">Company</li>
      </ul> */}
        </div>
        <div>
          <ul>
            <li
              className="nav-item login"
              onClick={() => {
                window.open("https://app.bluerack.co/");
              }}
            >
              Log in
            </li>
            <li className="nav-item">
              <button
                className="btn-light"
                onClick={() => {
                  navigate("/contact-us");
                }}
              >
                Get started <img alt="" src={arrow} width={11} />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};
const Home = () => {
  return (
    <main>
      <Header />
      <div className="landing-wrap">
        <LandingPage />
        <TrustedNWhy />
      </div>
      <Products />
      <Unprecedented />
      <SpendM />
      <Footer />
    </main>
  );
};

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <main className="contact-us">
      <Header />
      <LandingPage fromContact={true} />
      <Footer fromContact={true} />
    </main>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
