import React from "react";
import logo from "../assets/logo.png";
import arrow from "../assets/arrow.png";
import { useNavigate } from "react-router-dom";
const Footer = ({ fromContact }) => {
  const navigate = useNavigate();
  return (
    <div className="container footer-sec">
      {!fromContact && (
        <div className="flex-between">
          <div className="head-sec">
            <span>Elevate Your Business</span>
            <h4>Take your business to the next level with Bluerack</h4>
          </div>
          <button
            className="btn-light"
            onClick={() => {
              navigate("/contact-us");
            }}
          >
            Get started for free <img alt="" src={arrow} width={11} />
          </button>
        </div>
      )}
      <footer className="footer">
        <div className="footer-section company-info">
          <h2 className="footer-title">
            <img alt="" src={logo} height={50} />
          </h2>
          <address
            style={{
              maxWidth: 250,
              lineHeight: "24px",
            }}
          >
            Mahaveer Radiance, 1st floor,
            <br /> Opp: Metro Pillar number 1708, Madhapur, Rd Number 36, Jubilee
            Hills, Telangana 500081, India
          </address>
        </div>
        {/* <div className="footer-section">
          <h3 className="footer-heading">Resources</h3>
          <ul>
            <li>Help center</li>
            <li>Help docs</li>
            <li>Developer docs</li>
          </ul>
        </div>
        <div className="footer-section">
          <h3 className="footer-heading">Features</h3>
          <ul>
            <li>Affiliates</li>
            <li>PayPal Subscriptions</li>
            <li>Usage-based Billing</li>
          </ul>
        </div>
        <div className="footer-section">
          <h3 className="footer-heading">Platform</h3>
          <ul>
            <li>Affiliates</li>
            <li>PayPal Subscriptions</li>
            <li>Usage-based Billing</li>
          </ul>
        </div> */}
      </footer>
      <div className="footer-bottom">
        <p>Copyright © 2024 Bluerack. All rights reserved.</p>
        <div className="footer-links">
          <span>Terms</span>
          <span>Privacy policy</span>
          <span>Cookies policy</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
